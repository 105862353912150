import "font-awesome/css/font-awesome.min.css";
import "./assets/css/app.css";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import Loading from "./images/Loading";
import ChangeRoom from "./Pages/Rooms/ChangeRoom";
const DashboardPage = lazy(() => import('./Pages/Dashboard/DashboardPage'));
const ActivityPage = lazy(() => import('./Pages/Dashboard/ActivityPage'));
const StayOver = lazy(() => import('./Pages/Dashboard/StayOver'));
const OtpPage = lazy(() => import('./Pages/Dashboard/OtpPage'));
const GuestPage = lazy(() => import('./Pages/Dashboard/GuestPage'));
const BookingDetails = lazy(() => import('./Pages/Dashboard/BookingDetails'));
const EditDetails = lazy(() => import('./Pages/Dashboard/EditDetails'));
const ReservationPage = lazy(() => import('./Pages/Dashboard/ReservationPage'));
const TypographyPage = lazy(() => import('./Pages/TypographyPage'));
const Login = lazy(() => import('./Pages/Authentication/Login'));
const Registration = lazy(() => import('./Pages/Authentication/Registration'));
const ResetPassword = lazy(() => import('./Pages/Authentication/ResetPassword'));
const SelectProperty = lazy(() => import('./Pages/Authentication/SelectProperty'));
const SelectProperty1 = lazy(() => import('./Pages/Authentication/SelectProperty1'));
const ProfilePage = lazy(() => import('./Pages/Profile/ProfilePage'));
const ChangePasswordPage = lazy(() => import('./Pages/Profile/ChangePasswordPage'));
const Rates = lazy(() => import('./Pages/Rooms/Rates'));
const Baserate = lazy(() => import('./Pages/Rooms/Base_rate'));
const Employee = lazy(() => import('./Pages/Options/Employee'));
const Room = lazy(() => import('./Pages/Rooms/Room'));
const Bookings = lazy(() => import('./Pages/Bookings'));
const AddSubscriber = lazy(() => import('./Pages/Options/AddSubscriber'));
const Property = lazy(() => import('./Pages/PropertyDetails/Property'));
const EditProperty = lazy(() => import('./Pages/PropertyDetails/EditProperty'));
const Checkin = lazy(() => import('./Pages/CheckinAndOut/Checkin'));
const Roomservices = lazy(() => import('./Pages/Rooms/Roomservices'));
const Subscription = lazy(() => import('./Pages/Options/Subscription'));
const MakeReservation = lazy(() => import('./Pages/Reservation/MakeReservation'));
const Addroom = lazy(() => import('./Pages/Rooms/Add_room'));
const Payment_report = lazy(() => import('./Pages/Reports/Payment_report'));
const RoleTest = lazy(() => import('./Pages/RoleTest'));
const RoomDetails = lazy(() => import('./Pages/Inventory/RoomDetails'));
const Amenities = lazy(() => import('./Pages/Inventory/Amenities'));
const EditRoomDetails = lazy(() => import('./Pages/Inventory/EditRoomDetails'));
const Room_view = lazy(() => import('./Pages/Inventory/Room_view'));
const PermissionAssignAndEdit = lazy(() => import('./Pages/RolesAndPermission/PermissionAssignAndEdit'));
const RoleAssign = lazy(() => import('./Pages/RolesAssign/RoleTable'));
const Room_type = lazy(() => import('./Pages/Rooms/Room_type'));
const Calendar_View = lazy(() => import('./Pages/Calendar/Calendar_View'));
const Subscription_View = lazy(() => import('./Pages/Options/Subscription_View'));
const UserDetails = lazy(() => import('./Pages/Options/UserDetails'));
const Availability_Matrix = lazy(() => import('./Pages/Matrix/Availability_Matrix'));
const Scanner = lazy(() => import('./Pages/Scanner/Scanner'));
const BookingEngine = lazy(() => import('./Pages/Reservation/BookingEngine'));
const ViewDetailsPage = lazy(() => import('./Pages/Reservation/ViewDetailsPage'));
const UserInfoDetails = lazy(() => import('./Pages/Reservation/UserInfoDetails'));
const AccountBalance = lazy(() => import('./Pages/Reports/DailyActivityReports/AccountBalances'));
const CashierReport = lazy(() => import('./Pages/Reports/DailyActivityReports/CashierReport'));
const ArrivalsReport = lazy(() => import('./Pages/Reports/DailyActivityReports/ArrivalsReport'));
const DepartureReport = lazy(() => import('./Pages/Reports/DailyActivityReports/DepartureReport'));
const InHouseReport = lazy(() => import('./Pages/Reports/DailyActivityReports/InHouseReport'));
const NoShowReport = lazy(() => import('./Pages/Reports/DailyActivityReports/NoShowReport'));
const Cancellation = lazy(() => import('./Pages/Reports/DailyActivityReports/Cancellation'));
// const PaymentReconciliation = lazy(() => import('./Pages/Reports/DailyActivityReports/PaymentReconciliation'));
const DailyRevenueReport = lazy(() => import('./Pages/Reports/DailyActivityReports/DailyRevenueReport'));
const RoomMove = lazy(() => import('./Pages/Reports/DailyActivityReports/RoomMove'));
// const StockAndInventory = lazy(() => import('./Pages/Reports/StockAndInventory/StockAndInventory'));
const RoomAssign = lazy(() => import('./Pages/Reports/DailyActivityReports/RoomAssign'));
const Notes = lazy(() => import('./Pages/Reports/DailyActivityReports/Notes'));
const AmenitiesNotes = lazy(() => import('./Pages/Reports/Amenities/AmenitiesNotes'));
const Room_status_report = lazy(() => import('./Pages/Reports/ReOrientation/Room_status_report'));
const UserReconciliation = lazy(() => import('./Pages/Reports/DailyActivityReports/UserReconciliation'));
const PoliceReport = lazy(() => import('./Pages/Reports/PoliceReport/PoliceReport'));
const ProductionIndex = lazy(() => import('./Pages/Reports/Production/ProductionIndex'));
const FinancialIndex = lazy(() => import('./Pages/Reports/Financial/FinancialIndex'));
const BookingPage = lazy(() => import('./Pages/Reservation/BookingPage'));
const RoomViewDetail = lazy(() => import('./Pages/Inventory/RoomViewDetail'));
const GuestPolicy = lazy(() => import('./Pages/Policy/GuestPolicy'));
const Mat = lazy(() => import('./Pages/Matrix/Mat'));
const PayAmount = lazy(() => import('./Pages/Reservation/payAmount'));
const AddandEditAmenities = lazy(() => import('./Pages/PropertyOnboarding/AddandEditAmenities'));
const RoomChange = lazy(() => import('./Pages/Inventory/RoomChange'));
const NotesEdit = lazy(() => import('./Pages/Dashboard/NotesEdit'));
const GetItems = lazy(() => import('./Pages/StockAndInventory/GetItems'));
const InventoryItems = lazy(() => import('./Pages/StockAndInventory/InventoryItems'));
const SellReport = lazy(() => import('./Pages/Reports/StockAndInventory/SellReport'));
const PropertyInventory = lazy(() => import('./Pages/StockAndInventory/PropertyInventory'));
const Properties = lazy(() => import('./Pages/Reservation/Properties'));
const HotelInventory = lazy(() => import('./Pages/StockAndInventory/HotelInventory'));
const PropertyStatus = lazy(() => import('./Pages/PropertyOnboarding/PropertyStatus'));
const UpdatePronouns = lazy(() => import('./Pages/Profile/UpdatePronouns'));
const PaymentBook = lazy(() => import('./Pages/Reservation/PaymentBook'));
const PaymentSuccess = lazy(() => import('./Pages/Reservation/PaymentSuccess'));
const PaymentReconciliation = lazy(() => import('./Pages/Reservation/Paymentreconciliation'));
const InvoiceGenerate = lazy(() => import('./Pages/Reservation/InvoiceGenerate'));
const InvoiceInventory = lazy(() => import('./Pages/Reservation/InvoiceInventory'));
const AddPolicy = lazy(() => import('./Pages/Policy/AddPolicy'));
const EditPolicy = lazy(() => import('./Pages/Policy/EditPolicy'));
const GuestPolicies = lazy(() => import('./Pages/Policy/GuestPolicies'));
const TermsAndCondition = lazy(() => import('./Pages/Policy/TermsAndCondition'));
const FinalRate = lazy(() => import('./Pages/Matrix/FinalRate'));
const Receipt = lazy(() => import('./Pages/Reservation/Receipt'));
const OccupancyStatus = lazy(() => import('./Pages/Dashboard/OccupancyStatus'));
const NotFound = lazy(() => import('./Pages/NotFound/NotFound'));
const SampleScanner = lazy(() => import('./common/Scanner'));
const DailyReport = lazy(() => import('./Pages/Reports/DailyReports/DailyReport'));
const DailyCollectionReport = lazy(() => import('./Pages/Reports/DailyReports/DailyCollectionReport'));
const DailyCollectionPayment = lazy(() => import('./Pages/Reports/DailyReports/DailyCollectionPayment'));
const DailySummary = lazy(() => import('./Pages/Reports/DailyReports/DailySummary'));
const LedgerSummary = lazy(() => import('./Pages/Reports/DailyReports/LedgerSummary'));
const DailyDepositReport = lazy(() => import('./Pages/Reports/DailyReports/DailyDepositReport'));
const PaymentLinkSuccess = lazy(() => import('./Pages/Constants/PaymentLinkSuccess'));
const AmenitiesReport = lazy(() => import('./Pages/Reports/Amenities/AmenitiesReport'));
const CancellationPolicy = lazy(() => import('./Pages/Options/CancellationPolicy'));
const CancellationProcess = lazy(() => import('./Pages/Cancellation/CancellationProcess'));
const BookingReports = lazy(() => import('./Pages/Reports/BookingReports/BookingReports'));
const NewRoomType = lazy(() => import('./Pages/Rooms/NewRoomType'));
const DailyShiftReport = lazy(() => import('./Pages/Reports/DailyReports/DailyShiftReport'));
const Sign = lazy(() => import('./Pages/Reservation/Sign'));
const RegistrationForm = lazy(() => import('./Pages/Reservation/RegistrationForm'));
const RoomComment = lazy(() => import('./Pages/Rooms/RoomComment'));
const Refund = lazy(() => import('./Pages/Reservation/Refund'));
const Audit = lazy(() => import('./Pages/Reports/Audit/Audit'));
const RoomAllot = lazy(() => import('./Pages/Reservation/RoomAllot'));



function App() {

  //const url = "http://127.0.0.1:8000/"
  //const url = "https://HMS-dev-1712060760.ap-south-1.elb.amazonaws.com/"
 //const url = "https://pms-dev.gogarnet.net:8000/";
  const url = "https://pms-api.gogarnet.net/"
  // const url = "https://pms-api.gogarnet.net/"

  return (
    <Router>
      <Suspense fallback={<div><Loading /></div>}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <DashboardPage name="Dashboard" subtitle="Dashboard" url={url} />
            }
          />
          <Route exact path="/login" element={<Login url={url} />} />
          <Route
            exact
            path="/selectproperty"
            element={<SelectProperty url={url} />}
          />
          <Route
            exact
            path="/selectproperty1"
            element={<SelectProperty1 url={url} />}
          />
          <Route exact path="/register" element={<Registration url={url} />} />
          <Route
            exact
            path="/reset-password"
            element={<ResetPassword url={url} />}
          />
          <Route
            exact
            path="/change-password"
            element={
              <ChangePasswordPage
                url={url}
                name="change-password?id=97"
                subtitle="Change Password"
              />
            }
          />
          <Route
            exact
            path="/typography"
            element={<TypographyPage name="Typography" />}
          />
          <Route
            exact
            path="/Rates"
            element={
              <Rates
                url={url}
                subtitle="Room Management >> Rate Short-Term"
                name="Rate Short-Term"
              />
            }
          />
          <Route
            exact
            path="/base_rates"
            element={
              <Baserate
                url={url}
                subtitle="Room Management >> Rate Short-Term >> Base Rates"
                name="Base Rates"
              />
            }
          />
          <Route
            exact
            path="/Employee-Management"
            element={
              <Employee
                url={url}
                subtitle="Options >> Employee Management"
                name="Employee Management"
              />
            }
          />
          <Route
            exact
            path="/Room-Details"
            element={
              <Room
                url={url}
                subtitle="Room Management >> Room Details"
                name="Room Details"
              />
            }
          />

          <Route
            path="/Edit-Room-Type/:id"
            element={<Addroom url={url} name="Edit room" />}
          />
          <Route
            exact
            path="/Add-Room-Type/"
            element={
              <Addroom
                url={url}
                subtitle="Room Management >> Room Details >> Add Room"
                name="Add Room"
              />
            }
          />
          <Route
            exact
            path="/bookings"
            element={
              <Bookings
                url={url}
                subtitle="Room Management >> Room Details >> Room Status"
                name="Room Status"
              />
            }
          />
          <Route
            exact
            path="/Room-Services"
            element={
              <Roomservices
                url={url}
                subtitle="Room Management >> Room Services"
                name="Room Services"
              />
            }
          />
          <Route
            exact
            path="/Payment-Report"
            element={
              <Payment_report
                url={url}
                subtitle="Reports >> Payment Report"
                name="Payment Report"
              />
            }
          />
          <Route
            exact
            path="/Room-Reservation"
            element={
              <MakeReservation
                url={url}
                subtitle="Reservation >> Make Reservation"
                name="Make Reservation"
              />
            }
          />
          <Route
            exact
            path="/role"
            element={<RoleTest url={url} name="Role" />}
          />
          <Route
            exact
            path="/Room-Availability"
            element={
              <RoomDetails
                url={url}
                subtitle="Room Management >> Room Availability"
                name="Room Availability"
              />
            }
          />
          <Route
            exact
            path="/Amenities"
            element={<Amenities url={url} name="Inventory >> Room Details" />}
          />
          <Route
            exact
            path="/EditRoomDetails"
            element={<EditRoomDetails url={url} />}
          />
          <Route
            exact
            path="/Room_view/:id"
            element={
              <Room_view
                url={url}
                subtitle="Inventory >> Room View"
                name="Room View"
              />
            }
          />
          <Route
            exact
            path="/Inventory_view/:id"
            element={
              <Room_view
                url={url}
                subtitle="Inventory >> Inventory View"
                name="Inventory View"
              />
            }
          />
          <Route
            exact
            path="/Permission-Management"
            element={
              <PermissionAssignAndEdit
                url={url}
                subtitle="Roles & Permission >> Permission Management"
                name="Permission Management"
              />
            }
          />
          <Route
            exact
            path="/Manage-Roles"
            element={
              <RoleAssign
                url={url}
                name="Manage Roles"
                subtitle="Roles & Permission >> Manage Roles"
              />
            }
          />
          <Route
            exact
            path="/Room-Type"
            element={
              <Room_type
                url={url}
                name="Room Type"
                subtitle="Room Management >> Room Type"
              />
            }
          />
          <Route
            exact
            path="/Calendar-View"
            element={
              <Calendar_View
                url={url}
                subtitle="Reservation Management >> Calendar View"
                name="Calendar View"
              />
              
            }
          />
          <Route
            exact
            path="/Rate-Yearly"
            element={
              <Availability_Matrix
                url={url}
                subtitle="Room Management >> Rate Yearly"
                name="Rate Yearly"
              />
            }
          />
          <Route
            exact
            path="/Subscription/:id"
            element={
              <Subscription
                url={url}
                subtitle="Option >> Subscription"
                name="Subscription"
              />
            }
          />
          <Route
            exact
            path="/Subscription-Management/"
            element={
              <Subscription_View
                url={url}
                subtitle="Option >> Subscription Management"
                name="Subscription Management"
              />
            }
          />
          <Route
            exact
            path="/UserDetails/:id"
            element={
              <UserDetails
                url={url}
                subtitle="Option >> Subscription Management >> UserDetails"
                name="UserDetails"
              />
            }
          />
          <Route
            exact
            path="/AddSubscriber/"
            element={
              <AddSubscriber
                url={url}
                subtitle="Option >> Subscription Management >> AddSubscriber"
                name="AddSubscriber"
              />
            }
          />
          <Route
            exact
            path="/Property-Management"
            element={
              <Property
                url={url}
                subtitle="Option >> Property Management"
                name="Property Management"
              />
            }
          />
          <Route
            exact
            path="/edit-property"
            element={
              <EditProperty
                url={url}
                name="Edit Property"
                subtitle="Edit Property"
              />
            }
          />
          <Route
            exact
            path="/Room-Move"
            element={
              <Checkin
                url={url}
                subtitle=" Reservation Management >> Room Move"
                name="Room Move"
              />
            }
          />
          <Route
            exact
            path="/activityPage"
            element={
              <ActivityPage
                url={url}
                name="Activity"
                subtitle="Dashboard >> Activity"
              />
            }
          />
          <Route
            exact
            path="/reservationPage"
            element={
              <ReservationPage
                url={url}
                name="Reservations"
                subtitle="Reservation Page"
              />
            }
          />
          <Route
            exact
            path="/OccupancyStatus"
            element={
              <OccupancyStatus
                url={url}
                name="Occupancies"
                subtitle="Dashboard >> Occupancy Status"
              />
            }
          />
          {/* <Route exact path='/arrivalPage' element={<ArrivalPage url={url} subtitle="Arrival Page" />} />	
                <Route exact path='/departurePage' element={<DeparturePage url={url} subtitle="Departure Page" />} /> */}
          <Route
            exact
            path="/stayOver"
            element={<StayOver url={url} subtitle="Stay-Over Page" />}
          />
          <Route
            exact
            path="/otpPage"
            element={<OtpPage url={url} subtitle="OTP Page" />}
          />
          <Route
            exact
            path="/guestPage"
            element={<GuestPage url={url} number="0" subtitle="Guest Details" />}
          />
          <Route
            exact
            path="/bookingDetails"
            element={
              <BookingDetails
                url={url}
                name="Booking Details"
                subtitle="Booking Details"
              />
            }
          />
          <Route
            exact
            path="/payform"
            element={
              <UserInfoDetails
                url={url}
                name="Payment Form"
                subtitle="Payment Form"
              />
            }
          />
          <Route
            exact
            path="/editDetails"
            element={
              <EditDetails
                url={url}
                name="Booking Details"
                subtitle="Dashboard >> Departure >> Booking Details"
              />
            }
          />
          {/* <Route exact path='/BookingEngine' element={<BookingEngine url={url} subtitle="Reservation Page" />} />	 */}
          <Route
            exact
            path="/Booking-Engine/:pr"
            element={<BookingEngine url={url} subtitle="Reservation Page" />}
          />
          <Route
            exact
            path="/Booking-Engine/:owner/:pr"
            element={<BookingEngine url={url} subtitle="Reservation Page" />}
          />
          <Route
            exact
            path="/Booking-Engine/"
            element={
              <Properties url={url} subtitle="Booking Engine Property Page" />
            }
          />
          <Route
            exact
            path="/userInfoDetails"
            element={
              <UserInfoDetails
                url={url}
                name="Booking Details"
                subtitle="Reservation Page"
              />
            }
          />
          <Route
            exact
            path="/Room-Booking"
            element={
              <UserInfoDetails
                url={url}
                name="Room-Booking"
                subtitle="Reservation Page"
              />
            }
          />
          <Route
            exact
            path="/ViewDetailsPage"
            element={<ViewDetailsPage url={url} subtitle="Reservation Page" />}
          />
          <Route
            exact
            path="/profile"
            element={<ProfilePage url={url} name="Profile" />}
          />
          <Route
            exact
            path="/profile/:id"
            element={
              <ProfilePage
                url={url}
                name="Profile"
                subtitle="Dashboard >> Profile"
              />
            }
          />
          <Route
            exact
            path="/update-pronouns"
            element={
              <UpdatePronouns
                url={url}
                name="Update Pronouns"
                subtitle="Profile >> Update Pronouns"
              />
            }
          />
          {/* <Route exact path='/AdjustmentsReport/' element={<AdjustmentsReport url={url} name="Profile"/> } />     */}
          {/* <Route exact path='/Invoices/' element={<Invoices url={url} name="Invoices"/> } />   */}
          <Route
            exact
            path="/Account-Balances/"
            element={<AccountBalance url={url} name="AccountBalance" />}
          />
          <Route
            exact
            path="/CashierReport/"
            element={<CashierReport url={url} name="CashierReport" />}
          />
          <Route
            exact
            path="/Arrivals/"
            element={<ArrivalsReport url={url} name="ArrivalsReport" />}
          />
          <Route
            exact
            path="/Departures/"
            element={<DepartureReport url={url} name="DepartureReport" />}
          />
          <Route
            exact
            path="/In-House/"
            element={<InHouseReport url={url} name="InHouseReport" />}
          />
          <Route
            exact
            path="/No-Show/"
            element={<NoShowReport url={url} name="NoShowReport" />}
          />
          <Route
            exact
            path="/Cancellations/"
            element={<Cancellation url={url} name="Cancellation" />}
          />
          {/* <Route exact path='/PaymentReconciliation/' element={<PaymentReconciliation url={url} name="PaymentReconciliation"/> } />   */}
          <Route
            exact
            path="/Daily-Revenue/"
            element={<DailyRevenueReport url={url} name="DailyRevenueReport" />}
          />
          <Route
            exact
            path="/Room-Move-Report/"
            element={<RoomMove url={url} name="RoomMove" />}
          />
          {/* <Route exact path='/items/' element={<StockAndInventory url={url} name="StockAndInventory"/> } />  */}
          <Route
            exact
            path="/Room-Assignments/"
            element={<RoomAssign url={url} name="RoomAssign" />}
          />
          <Route
            exact
            path="/Room-Notes/"
            element={<Notes url={url} name="Notes" />}
          />
          <Route
            exact
            path="/Amenities-Notes/"
            element={<AmenitiesNotes url={url} name="Notes" />}
          />

          <Route
            exact
            path="/User-Reconciliation/"
            element={<UserReconciliation url={url} name="UserReconciliation" />}
          />
          <Route
            exact
            path="/Police/"
            element={<PoliceReport url={url} name="PoliceReport" />}
          />
          <Route
            exact
            path="/Production-Reports"
            element={<ProductionIndex url={url} subtitle="Production Reports" />}
          />
          <Route
            exact
            path="/Financial-Reports"
            element={<FinancialIndex url={url} subtitle="Financial Reports" />}
          />
          <Route
            exact
            path="/Daily-Report"
            element={<DailyReport url={url} subtitle="Daily Report" />}
          />
          <Route
            exact
            path="/Daily-Deposit-Report"
            element={<DailyDepositReport url={url} subtitle="Daily Report" />}
          />
          <Route
            exact
            path="/Daily-Collection-Report"
            element={
              <DailyCollectionReport
                url={url}
                subtitle="Daily Collection Report"
              />
            }
          />
          <Route
            exact
            path="/Daily-Collection-Payment"
            element={
              <DailyCollectionPayment
                url={url}
                subtitle="Daily Collection Payment Report"
              />
            }
          />
          <Route
            exact
            path="/Daily-Summary"
            element={<DailySummary url={url} subtitle="Daily Summary" />}
          />
          <Route
            exact
            path="/Ledger-Summary"
            element={<LedgerSummary url={url} subtitle="Ledger Summary" />}
          />
          <Route
            exact
            path="/Daily-Shift-Report"
            element={<DailyShiftReport url={url} subtitle="Daily Shift" />}
          />

          <Route
            exact
            path="/bookingPage"
            element={<BookingPage url={url} subtitle="Reservation Page" />}
          />
          <Route
            exact
            path="/RoomViewDetail/:pr/:id"
            element={
              <RoomViewDetail
                url={url}
                subtitle="Inventory >> Room View"
                name="Room View"
              />
            }
          />
          <Route
            exact
            path="/RoomViewDetail/:owner/:pr/:id"
            element={
              <RoomViewDetail
                url={url}
                subtitle="Inventory >> Room View"
                name="Room View"
              />
            }
          />
          <Route
            exact
            path="/Policy-Management/"
            element={
              <GuestPolicies
                url={url}
                subtitle="Inventory >> Room View"
                name="Room View"
              />
            }
          />
          <Route
            exact
            path="/GuestPolicy/:owner/:pr"
            element={
              <GuestPolicy
                url={url}
                subtitle="Inventory >> Room View"
                name="Room View"
              />
            }
          />
          <Route
            exact
            path="/Mat"
            element={
              <Mat url={url} subtitle="Inventory >> Room View" name="Mat" />
            }
          />
          <Route
            exact
            path="/PayAmount"
            element={
              <PayAmount url={url} subtitle="Inventory >> Room View" name="Mat" />
            }
          />
          <Route
            exact
            path="/add-Amenities"
            element={
              <AddandEditAmenities
                url={url}
                subtitle="Amenities Management >> Add new Amenities"
                name="Add new Amenities"
              />
            }
          />
          <Route
            exact
            path="/edit-Amenities/:id"
            element={
              <AddandEditAmenities
                url={url}
                subtitle="Amenities Management >> Edit Amenities"
                name="Edit Amenities"
              />
            }
          />
          <Route
            exact
            path="/Amenities-Status"
            element={
              <PropertyStatus
                url={url}
                subtitle="Property Status"
                name="Property Status"
              />
            }
          />
          <Route
            exact
            path="/Room-Change"
            element={
              <RoomChange
                url={url}
                subtitle="Reservation >> Check-in >> room-change"
                name="Room Change"
              />
            }
          />
          <Route
            exact
            path="/notesedit"
            element={<NotesEdit url={url} name="Notes" subtitle="Notes" />}
          />
          <Route
            exact
            path="/Buy-Inventory"
            element={
              <GetItems
                url={url}
                subtitle="Stock & Inventory >> Buy Inventory"
                name="Buy Inventory"
              />
            }
          />
          <Route
            exact
            path="/Sell-Inventory"
            element={
              <InventoryItems
                url={url}
                subtitle="Stock & Inventory  >> Sell Inventory"
                name="Sell Inventory"
              />
            }
          />
          <Route
            exact
            path="/Sell-Inventory-Report"
            element={
              <SellReport
                url={url}
                subtitle="Reports >> Inventory Sell Report"
                name="Inventory Sell Report"
              />
            }
          />
          <Route
            exact
            path="/Amenities-Reservation"
            element={
              <PropertyInventory
                url={url}
                subtitle="Reservation Management >> Book Inventory"
                name="Book Inventory"
              />
            }
          />
          <Route
            exact
            path="/Hotel-Amenities"
            element={<HotelInventory url={url} />}
          />
          <Route
            path="/roombooking"
            element={<PaymentBook url={url} subtitle="Payment" name="Payment" />}
          />
          <Route
            path="/success"
            element={
              <PaymentSuccess url={url} subtitle="Payment" name="Payment" />
            }
          />
          <Route
            path="/invoice"
            element={
              <InvoiceGenerate url={url} subtitle="Invoice" name="Invoice" />
            }
          />
          <Route
            path="/invoiceinventory"
            element={
              <InvoiceInventory url={url} subtitle="Invoice" name="Invoice" />
            }
          />
          <Route
            path="/addpolicy"
            element={<AddPolicy url={url} subtitle="Policy" name="Add Policy" />}
          />
          <Route
            path="/editPolicy"
            element={
              <EditPolicy url={url} subtitle="Policy" name="Edit Policy" />
            }
          />
          <Route
            path="/Payment-Reconciliation"
            element={<PaymentReconciliation url={url} />}
          />
          <Route
            path="/termsandconditions"
            element={<TermsAndCondition url={url} />}
          />
          <Route path="/final-rate" element={<FinalRate url={url} />} />
          <Route
            path="/securityreceipt"
            element={
              <Receipt url={url} name="Security Deposit" subtitle="Receipt" />
            }
          />

          <Route
            path="/Room-Status-Report"
            element={<Room_status_report url={url} />}
          />
          <Route path="*" element={<NotFound />} />

          <Route path="/Sample-Scanner" element={<SampleScanner />} />

          <Route
            path="/Payment-Success/:link_id/:invoice_id"
            element={<PaymentLinkSuccess url={url} />}
          />

          <Route
            path="/Amenities-Bookings"
            element={<AmenitiesReport url={url} />}
          />
          <Route
            exact
            path="/Cancellation-Policy"
            element={
              <CancellationPolicy
                url={url}
                subtitle="Options >> Cancellation Policy"
                name="Cancellation Policy"
              />
            }
          />

          <Route
            exact
            path="/cancel-booking"
            element={
              <CancellationProcess
                url={url}
                subtitle="Options >> Cancellation Process"
                name="Cancellation Process"
              />
            }
          />
          <Route
            exact
            path="/extend-booking"
            element={
              <CancellationProcess
                url={url}
                subtitle="BookingDetails >> Extend Stay Process"
                name="Extend Stay Process"
              />
            }
          />
          <Route path="/Key-Reports" element={<DepartureReport url={url} />} />
          <Route
            path="/Audit"
            element={<Audit subtitle="Audit" name="Audit Process" url={url} />}
          />
          <Route
            path="/NewRoomType"
            element={
              <NewRoomType
                url={url}
                subtitle="BookingDetails >> Extend Stay"
                name="Extend Stay"
              />
            }
          />

          <Route
            path="/ChangeRoomType"
            element={
              <ChangeRoom
                url={url}
                subtitle="BookingDetails >> Reservation"
                name="Change Reservation Date"
              />
            }
          />
          {/* <Route
          path="/sign"
          element={
            <Sign
              url={url}
              string={true}
              subtitle="TopazScanner >> Signature"
              name="Topaz Scanner"
            />
          }
        /> */}
          <Route
            exact
            path="/Room-Comment/:roomId"
            element={
              <RoomComment
                url={url}
                subtitle="Room Management >> Room Comments"
                name="Room Comments"
              />
            }
          />

          <Route
            path="/registrationform"
            element={
              <RegistrationForm
                url={url}
                subtitle="BookingDetails >> Registration Form"
                name="Registration Form"
              />
            }
          />
          <Route
            path="/refund"
            element={
              <Refund url={url} subtitle="Refund >> Refund" name="Refund" />
            }
          />
          {/* <Route
          path="/RoomAllot"
          element={
            <RoomAllot url={url} subtitle="Refund >> Refund" name="Refund" />
          }
        /> */}
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
